//const apiUrl = process.env.REACT_APP_API_URL;
const apiUrl = "https://1bca-2a12-5940-8606-00-2.ngrok-free.app";
const Endpoints = {
    REFERENCES: {
        MODEL: `${apiUrl}/api/references/models`,
        GEOPOINT: `${apiUrl}/api/references/geo`
    },
    BOT:{
        CONTROLLER:{
            ADD: `${apiUrl}/api/history/add`,
            GET: `${apiUrl}/api/history/get`,
            COUNT: `${apiUrl}/api/history/count`,
        }
    }
}

export default Endpoints;